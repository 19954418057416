import React from 'react';
import SEO from './../components/SEO';


export default function Contact() {
  return (
    <>
    <SEO title="Contact" />
    <div className="relative ">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className=" py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="font-bold tracking-tight text-gray-900">Contact Us</h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              We would love to hear from you and find out how we can be of service. Please reach out by emailing the address below, giving us a call, or by filling out the form. 
            </p>
            <div className="mt-8">
                <p className="text-lg mt-4 leading-6 text-gray-500"><strong>Location: </strong>Lake Jackson, Tx</p>
            </div>
            <div className="">
                <p className="text-lg mt-4 leading-6 text-gray-500"><strong>Phone: </strong><a href="tel:832-344-7006" className="ml-3 underline hover:text-blue-500">+1 (832) 344-7006</a></p>
            </div>
            <div className="">
                <p className="text-lg mt-4 leading-6 text-gray-500"><strong>Email: </strong><a href="mailto:brodysbusybees@gmail.com" className="ml-3 underline hover:text-blue-500">brodysbusybees@gmail.com</a></p>
            </div>

            </div>
        </div>
        <div className=" py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form name="contact" form-name="contact" method="POST" data-netlify-honeypot="bot-field" netlify data-netlify="true" className="grid grid-cols-1 gap-y-6">
            <input type="hidden" name="form-name" value="contact" />  
            <div>
                <label htmlFor="full_name" className="sr-only">
                  Full name
                </label>
                <input
                  type="text"
                  name="full_name"
                  id="full_name"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Full name"
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Email"
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  Phone
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Phone"
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-yellow-400 border-gray-300 rounded-md"
                  placeholder="Message"
                  defaultValue={''}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-gray-900 bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}